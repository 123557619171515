@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

/* base styles */
* {
    margin: 0;
    font-family: "Quicksand", serif;
    color: #333;
}

.navbar {
    padding: 20px;
    display: flex;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
    border-bottom: 1px solid #f2f2f2;
}

.navbar h1 {
    color: #FBAAB6;
}

.navbar .links {
    margin-left: auto;
}

.navbar a {
    margin-left: 16px;
    text-decoration: none;
    padding: 6px;
}

.navbar a:hover {
    color: #FBAAB6;
}

.content {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
}

/* blog previews / list */
.blog-preview {
    padding: 10px 16px;
    margin: 20px 0;
    border-bottom: 1px solid #fafafa;
}

.blog-preview:hover {
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
}

.blog-preview h2 {
    font-size: 20px;
    color: #FBAAB6;
    margin-bottom: 8px;
}

.blog-preview a {
    text-decoration: none;
}

/* blog details page */
.blog-details h2 {
    font-size: 20px;
    color: #FBAAB6;
    margin-bottom: 10px;
}

.blog-details div {
    margin: 20px 0;
}

.blog-details button {
    background: #FBAAB6;
    color: #fff;
    border: 0;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
}

/* create new blog form */
.create {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
}

.create label {
    text-align: left;
    display: block;
}

.create h2 {
    font-size: 20px;
    color: #FBAAB6;
    margin-bottom: 30px;
}

.create input, .create textarea, .create select {
    width: 100%;
    padding: 6px 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    box-sizing: border-box;
    display: block;
}

.create button {
    background: #FBAAB6;
    color: #fff;
    border: 0;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
}

.about h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    color: #333;
}

.about hr {
    border: none;
    border-top: 2px dashed #ccc;
    margin: 1rem 0;
}

.about ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.about li {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
    position: relative;
}

.about li::before {
    content: "🔹";
    position: absolute;
    left: 0;
    color: #6c63ff;
}

.about h3 {
    font-size: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    color: #555;
}

.about p {
    margin: 0;
}